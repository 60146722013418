import AppAPI from "../../AppAPI";
import ProductGroup from "./ProductGroup";

/**
 * The Tally app. In this app the products should be located.
 * Tis app should extend the AppAPI. Inside this AppAPI the calls to the group are located.
 */
export default class PluginTally extends AppAPI {

    products: ProductGroup[] = [];
    group_code: string = "";
    dummyData2: ProductGroup[] = [];

    constructor(group_code : string) {
        super("APP_TALLY");


        this.group_code = group_code;

        this.dummyData2 = [
            new ProductGroup(
                0,
                group_code,
                0,
                "Bier kratten",
                'undefined',
                "Afbeelding van een bier",
                "Flesje bier",
                "DUMMY_PRODUCT_GROUP_UUID_" + Math.random(),
                "CratesPils",
                [],
                0,
                []
            ),
            new ProductGroup(
                0,
                group_code,
                0,
                "Wijn",
                'undefined',
                "Afbeelding van een fles wijn",
                "Fles wijn",
                "DUMMY_PRODUCT_GROUP_UUID_" + Math.random(),
                "Wine",
                [],
                0,
                []
            ),
            new ProductGroup(
                0,
                group_code,
                0,
                "Speciaal Bier",
                'undefined',
                "Afbeelding van condooms",
                "Een condoom.",
                "DUMMY_PRODUCT_GROUP_UUID_" + Math.random(),
                "CraftBeer",
                [],
                0,
                []
            ),
            // new ProductGroup(
            //     0,
            //     0,
            //     0,
            //     0,
            //     group_code,
            //     0,
            //     "Pitt bier",
            //     require("../../../../assets/defaultProductImages/pittBier.jpg"),
            //     "Afbeelding een blik en een fles pitt bier",
            //     "Een fles pitt bier.",
            //     "DUMMY_PRODUCT_GROUP_UUID_" + Math.random(),
            //     undefined,
            // ),
        ];
    }


    static async createApp(group_code: string) {
        const plugin = new PluginTally(group_code);
        await plugin.initApp();
        return plugin;
    }

    /**
     *
     * @return {Promise<void>}
     */
    private async initApp() {
        this.products = [];


        // @ts-ignore
        const stockBalance = await ProductGroup.post("/mp/v1/tally/v2/products/stock",
            {groupUUID: this.group_code}
        )
            .then((res: any) => {
                return Promise.resolve(res.data.stockBalance)
            })
            .catch((err: any) => {
                if (err && err.response && err.response.message) {
                    return Promise.reject(err.response.message);
                }
                return Promise.reject("Onbekende error. ");
            });

        await ProductGroup.post(
            "/mp/v1/tally/v2/products/getAll",
            {
                groupUUID: this.group_code,
            },
            {}
        )
            .then((res) => {

                const productGroups = res.data.productGroups;
                const totalConsumed = res.data.totalConsumed;


                // Push every fetched product to the products list.
                for (const index in productGroups) {
                    const product = productGroups[index];

                    const totalConsumedThisProduct = totalConsumed.filter((element: any) => {
                        return element.productGroupUUID === product.productGroupUUID;
                    });

                    const stockBalanceThisProduct = stockBalance.filter((element: any) => {
                        return element.productGroupUUID === product.productGroupUUID
                    }).map((element: any) => {
                        return {
                            userUUID: element.userUUID,
                            qtyPurchased: element.qtyPurchased,
                            qtyConsumed: element.qtyConsumed,
                            qtyBalance: element.qtyBalance,
                        }
                    });

                    this.products.push(
                        new ProductGroup(
                            // product.standardPrice,
                            // product.averagePrice,
                            product.totalStockQuantity,
                            // product.totalStockMoney,
                            product.groupUUID,
                            product.active,
                            product.productGroupName,
                            product.productGroupImageUrl,
                            product.productGroupImageAltText,
                            product.description,
                            product.productGroupUUID,
                            product.productGroupType,
                            totalConsumedThisProduct,
                            product.stockMoneyDifference,
                            stockBalanceThisProduct
                        )
                    );
                }

                return Promise.resolve();
            })
            .catch((err) => {
                console.error(err);

                let errorMessage = err;

                if (err && err.response && err.response.data) {
                    errorMessage = err.response.data.message;
                }

                alert("Kon producten niet downloaden van de server: " + errorMessage);

                return Promise.reject(errorMessage);
            });

        return Promise.resolve();
    }

    getAlbertHeijnGroceries() : ProductGroup[] {

        return this.dummyData2;
    }


    /**
     * In the future this might be handled by the server.
     * @return {Promise<Promise<any>|Promise<never>>}
     */
    async getStandardProducts(): Promise<ProductGroup[]> {
        // @ts-ignore
        return await PluginTally.get("/mp/v1/tally/v2/products/getProductCategories")
            .then((res: any) => {

                const mainCategories = res.data.mainCategories;
                const defaultProductCategories = res.data.defaultProductCategories;

                return Promise.resolve({
                    mainCategories: mainCategories,
                    defaultProductCategories: defaultProductCategories,
                });
            })
            .catch((err: any) => {

                console.error(err);

                if (err.response && err.response.data) {
                    return Promise.reject("Kon producten niet downloaden: " + err.response.data.message);
                }
                return Promise.reject("Kon groepen niet downloaden: Onbekende error. ");
            })

    }

    async getSubProductGroups(productGroup: string) {
        // @ts-ignore
        return await PluginTally.get("/mp/v1/tally/v2/products/productSubGroup/" + productGroup)
            .then((res: any) => {
                    return Promise.resolve(res.data.productSubGroups)
                }
            )
            .catch((err: any) => {

                console.error(err);

                if (err.data) {
                    return Promise.reject("Kon producten niet downloaden: " + err.data.message);
                }
                return Promise.reject("Kon groepen niet downloaden: Onbekende error. ");
            })
    }


    async tally(productGroupUUID: string, userUUID: string, quantity: number, description: string) {

        const productGroup: Array<ProductGroup> = this.products.filter(
            (product) => product.getProductCode() === productGroupUUID
        );

        if (productGroup.length !== 1) {
            console.error(productGroup);
            return Promise.reject("ProductCartItem is niet gevonden. ");

        }

        // if (productGroup[0].getTotalStockQuantity() < quantity) {
        //     return Promise.reject(
        //         "Er is minder op vooraad dan dat je nu wilt turven. "
        //     );
        // }

        if (isNaN(quantity)) {
            return Promise.reject("Quantity is not a number! ");
        }

        if (!userUUID || !productGroupUUID) {
            return Promise.reject("Arguments are not valid. ");
        }

        return await PluginTally.post("/mp/v1/tally/v2/products/tallyOne", {
            groupUUID: this.group_code,
            productGroupUUID: productGroupUUID,
            memberUUID: userUUID,
            quantity: quantity,
            description: description
        }).catch(error => {

            if (error && error.response && error.response.data) {
                console.error(error.response);
                return Promise.reject(error.response.data.message);
            }

            console.error(error);
            return Promise.reject(error);

        })
    }

    async addStockAddBalance(
        arrStocks: Array<{
            productGroupUUID: String,
            quantity: BigInteger,
            costsMoney: BigInteger,
        }>,
        arrStocksSettlements: Array<{
            memberUUID: String,
            addBalanceMoney: BigInteger,
        }>,
        description: string,
        totalMoney: number
    ) {
        if (!arrStocks || arrStocks.length <= 0) {
            return Promise.reject("Kies een product ");
        }


        if (!arrStocksSettlements || arrStocksSettlements.length <= 0) {
            return Promise.reject("Verdeel de waarde over gebruikers. ");

        }

        const group_code = this.group_code;
        const timestamp = Date();

        // console.log({
        //     groupUUID: group_code,
        //     timestamp: timestamp,
        //     arrStocks: arrStocks,
        //     arrStocksSettlements: arrStocksSettlements,
        //     description: description
        //     }
        // )

        return await PluginTally.post("/mp/v1/tally/v2/stocks/stocksSettlements", {
            groupUUID: group_code,
            timestamp: timestamp,
            arrStocks: arrStocks,
            arrStocksSettlements: arrStocksSettlements,
            description: description,
            totalMoney: totalMoney,
        })
            .then((res: any) => {
                if (res.response && res.response.data && res.response.data.message) {
                    return Promise.resolve(res.response.data.message);
                } else {
                    // console.log("RESPONSE WAS: ");
                    // console.log(res.response);

                    return Promise.resolve("Transaction succeeded.");

                }
            })
            .catch((err) => {
                let errorMessage = err;

                if (err.response && err.response.data) {
                    errorMessage = err.response.data.message;
                }

                return Promise.reject(errorMessage);
            });
    }

    async addStock(
        product_code: string,
        totalPrice: number,
        user_codes: string[],
        userQuantities: number[],
        userPayPrice: number[],
        description: string
    ) {
        // console.log(
        //     "ProductCartItem code: ", product_code
        // )
        //
        // console.log("totalPrice", totalPrice)
        // console.log("user_codes", user_codes)
        // console.log("userQuantities", userQuantities)
        // console.log("userPayPrice", userPayPrice)
        // console.log("description", description)

        totalPrice = Number(totalPrice);
        userQuantities = userQuantities.map((price) => Number(price));

        if (totalPrice === 0 || isNaN(totalPrice)) {
            return Promise.reject("Prijs kan niet gelijk zijn aan 0. ");
        }

        // if (totalPrice !== userQuantities.reduce((a, b) => a + b, 0)) {
        //     return Promise.reject("De totale prijs moet gelijk zijn aan de totaal verdeelde prijs. ")
        // }

        if (user_codes.length !== userQuantities.length) {
            return Promise.reject(
                "Transactie geannuleerd: Input lengte is verkeerd. "
            );
        }

        const arrStockMutations = [];

        for (const index in user_codes) {
            if (userQuantities[index] > 0) {
                arrStockMutations.push({
                    productGroupUUID: product_code,
                    quantity: userQuantities[index],
                    price: userPayPrice[index],
                    totalMoney: userPayPrice[index],
                    memberUUID: user_codes[index],
                });
            }
        }

        return await PluginTally.post("/mp/v1/tally/v2/stocks/addStock", {
            groupUUID: this.group_code,
            timestamp: Date(),
            grandTotal: totalPrice,
            arrStockMutations: arrStockMutations,
            description: description,
        })
            .then((res) => {
                return Promise.resolve("Transactie is verwerkt. ");
            })
            .catch((err) => {
                console.error(err);

                let errorMessage = err;

                if (err.response && err.response.data) {
                    errorMessage = err.response.data.message;
                }

                return Promise.reject(
                    "Transaction geannuleerd: " + errorMessage
                );
            });
    }

    /**
     * Return all the product codes used in a group.
     * @return {*}
     */
    getAllProducts(): Array<ProductGroup> {
        return this.products;
    }
}

