import Database from "../global/config/Database";
// @ts-ignore
import jwt from "jsonwebtoken";
import setAuthorizationToken from "../global/config/Autorization";
import ENV from "../global/config/environment";

const ERROR_PASSWORDS_DONT_MATCH = "Wachtwoorden komen niet overeen";
const DEFAULT_PROFILE_IMAGE = require("../assets/user_default_profile_image.png");


/**
 * With this class you can get user parameters.
 * You need to create one User at the time.
 */
export default class User extends Database {

    lastName: string;
    firstName: string;
    userImageUrl: URL;
    email: string;
    nickname: string = '';
    group_codes: string[];
    user_code: string;

    // new variables
    name: string;
    phone: string;
    userImageAltText: string;

    constructor(
        userUUID: string,
        name: string,
        firstName: string,
        lastName: string,
        email: string,
        phone: string,
        userImageUrl: string,
        userImageAltText: string,
        group_codes: string[]
    ) {
        super();
        this.user_code = userUUID;
        this.name = name;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
        this.group_codes = group_codes;

        console.warn("Hier wordt een custom URL gemaakt! ");
        if (!userImageUrl) {
            this.userImageUrl = DEFAULT_PROFILE_IMAGE;
        } else {
            this.userImageUrl = new URL(ENV.apiURL.SERVER_URL + "/" + userImageUrl)
        }

        if (!userImageAltText) {
            userImageAltText = "User image. ";
        }

        this.userImageAltText = userImageAltText;

    }

    static async createUser(user_code: string) {
        return await User.initializeUser(user_code);
    }


    static async initializeUser(user_code: string) {

        // @ts-ignore
        return await User.post("/api/v1/users/getUserById", {userUUID: localStorage.user_code})
            .then((res: any) => {

                const {user, group} = res.data;

                const userObject = new User(
                    user_code,
                    user.name,
                    user.firstName,
                    user.lastName,
                    user.email,
                    user.phone,
                    user.userImageUrl,
                    user.userImageAltText,
                    group
                );

                return Promise.resolve(userObject);
            })
            .catch((err: any) => {
                console.error(err);

                const ERROR_MESSAGE_FETCHING_USER_WENT_WRONG = "Kon geen informatie van de server verkrijgen. ";
                alert(err);
                return Promise.reject(ERROR_MESSAGE_FETCHING_USER_WENT_WRONG);
            });
    }

    /**
     *
     * @return {"PLACEHOLDER_AVATAR"}
     */
    getAvatar() {
        return this.userImageUrl;
    }

    async setAvatar(imageFile: any) {

        //TODO make call to server. IFF success. return set this.uri.

        const bodyFormData = new FormData();
        bodyFormData.append("image", imageFile);

        return await User.put("/api/v2/user/edit", bodyFormData)
            .then(res => {
                return Promise.resolve(URL.createObjectURL(imageFile))
            })
            .catch(err => {
                if (err.response) {
                    return Promise.reject(err.response.message);
                }
                return Promise.reject(err);
            })


    }


    getEmail() {
        return this.email
    }

    getFirstName() {
        return this.name
    }

    getName() {
        return this.name
    }


    getLastName() {
        return this.lastName
    }

    getJoinedGroupCodes() {
        return this.group_codes;
    }


    setName(name: string) {
        this.firstName = name;
    }

    getUserCode() {
        return this.user_code;
    }

    /**
     *
     * @param firstName
     * @param lastName
     * @return {Promise<Promise<any>|Promise<never>>}
     */
    async changeUserInformation(firstName: string, lastName: string) {

        this.firstName = firstName;
        this.lastName = lastName;

        return Promise.reject("Function not yet implemented!");
    }

    /**
     *
     * @param old_password
     * @param new_password
     * @param new_password_check
     * @return {Promise<Promise<never>>}
     */
    async changePassword(old_password: string, new_password: string, new_password_check: string) {
        return Promise.reject("Function not yet implemented!");
    }

    /**
     *
     * @param new_email
     * @return {Promise<Promise<any>|Promise<never>>}
     */
    async changeEmail(new_email: string) {
        return Promise.reject(new Error("Function not yet implemented!"));
    }

    /**
     * Reset password
     * @param email
     * @return {Promise<Promise<Promise<never>|Promise<string>|undefined>|Promise<string>>}
     */
    static async forgotPassword(email: string) {

        alert("Inside the forgot password check")
        if (email === "" || !email) {
            return Promise.reject("Please enter valid email. ");
        }

        return Promise.reject("Function is not yet implemented. ");
    }

    /**
     *
     * @param email
     * @param password
     * @return {Promise<T|Promise<Promise<never>|*>>}
     */
    static async loginUser(email: string, password: string) {

        const ERROR_ENTER_VALID = "Please enter a valid ";

        if (email === "") {

            const error = {response: {data: {message: ERROR_ENTER_VALID + "email"}}};

            return Promise.reject(error);
        }

        if (password === "") {
            const error = {response: {data: {message: ERROR_ENTER_VALID + "password"}}};

            return Promise.reject(error);
        }

        // @ts-ignore
        return await User.post("/api/v2/user/pub/login",
            {
                email: email.toLowerCase(),
                password: password
            }
        )
            .then(async (res: any) => {

                keepUserLoggedIn(res.data.token);
                return Promise.resolve();
            })
            .catch((err: any) => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    async logOut() {
        console.error("Not yet logging out on the server! ");
        return Promise.resolve()
    }

    async editUser(name: String) {

        if (!name || name.length < 1) {
            return Promise.reject("Naam moet minimaal 1 character lang zijn. ")
        }

        return await User.put("/api/v2/user/edit",
            {firstName: name, name: name}
        )
            .then(res => {
                return Promise.resolve(res);
            })
            .catch(err => {
                console.error(err);

                if (err.response && err.response.data) {
                    return Promise.reject(err.response.data.message);
                }
                console.log("HIERO")
                return Promise.reject(err.toString());
            })
    }


}


async function keepUserLoggedIn(userToken: string,) {

    if (!userToken) {
        console.error("Usertoken in undefined: " + userToken);
    }

    setAuthorizationToken(userToken);
    const decodedToken = jwt.decode(userToken);

    localStorage.setItem("user_code", decodedToken.userUUID);
    localStorage.setItem("email", decodedToken.email);
    localStorage.setItem("jwtToken", userToken);

}

/**
 *
 * @param email
 * @param password
 * @param passwordDoubleCheck
 * @param first_name
 * @param acceptedTerms : Date (in int) when the user clicked on the checkbox.
 * @return {Promise<Promise<never>|undefined>}
 */
export async function registerUser(
    email: String,
    password: String,
    passwordDoubleCheck: String,
    first_name: String,
    acceptedTerms: Date,
) {

    // Password should match.
    if (password !== passwordDoubleCheck) {

        const error = {response: {data: {message: ERROR_PASSWORDS_DONT_MATCH}}};

        return Promise.reject(error);
    }

    // @ts-ignore
    return await User.post("/api/v2/user/pub/register", {
        email: email.toLowerCase(),
        password: password,
        firstName: first_name,
        lastName: " ",
        name: first_name,
        acceptedTerms: acceptedTerms,
    })
        .then(async (res: any) => {

            await keepUserLoggedIn(res.data.token);
            return Promise.resolve(res.data.token);
        })
        .catch((err: any) => {
            return Promise.reject(err)
        })

}

