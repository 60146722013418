import React from "react";
import './Loading.css';

/**
 * When an component is loading, show its loading.
 * @property text: Add text to indicate what it loading.
 */
export default function Loading(props) {

    const {text} = props;
    return (
        <div className={"".concat(props.className)}>
            <div className="col-12 text-center ">
                <div className="loader "/>
                <div>Laden... {text}</div>
            </div>
        </div>
    )

}