import Database from "../global/config/Database";
import PluginTally from "./apps/tally/javascript/PluginTally";
import User from "../user/User";

import ENV from "../global/config/environment";
import TransactionClass from "./history/TransactionClass";
import { Moment } from "moment";

const DEFAULT_PROFILE_IMAGE = require("../assets/user_default_profile_image.png");

/**
 * Group preview is used to create a smaller group object.
 * User for e.g. the group overview page. This does not need to init whole groups!
 */
export class GroupPreview extends Database {
  avatar?: string;
  name: string = "";
  group_code = "";

  constructor(group_code: string) {
    super();
    this.group_code = group_code;
  }

  static async createGroupPreview(group_code: string) {
    console.log("Creating group preivew with code: ", group_code);

    const groupPreview = new GroupPreview(group_code);
    await groupPreview.initGroupPreview();
    console.log("What is the group Preview?", groupPreview);
    return groupPreview;
  }

  async initGroupPreview() {
    // @ts-ignore
    await GroupPreview.post("/api/v1/groups/getGroupPreview", {
      groupUUID: this.group_code,
    })
      .then((res: any) => {
        const groupPreview = res.data.group;

        this.avatar = groupPreview.image;
        this.name = groupPreview.name;

        return Promise.resolve();
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  getGroupCode() {
    return this.group_code;
  }

  /**
   * @return {String}
   */
  getGroupImage() {
    return this.avatar;
  }

  /**
   *
   * @return {String}
   */
  getName() {
    return this.name;
  }

  /**
   *
   * @return {number}
   */
  getUserBalance() {
    return 0;
  }
}

/**
 * A members is a user inside a group.
 */
export class Member extends User {
  owner: boolean = false;
  balance: number;
  admin: boolean = false;
  active: boolean = true;
  sortingOrder: number;

  constructor(
    isOwner: boolean | number,
    isAdmin: boolean | number,
    balance: number,
    userUUID: string,
    name: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: any,
    userUserImageUrl: string,
    userImageAltText: string,
    sortingOrder: number,
    active: boolean
  ) {
    super(
      userUUID,
      name,
      firstName,
      lastName,
      email,
      phone,
      userUserImageUrl,
      userImageAltText,
      []
    );

    this.owner = isOwner === 1;
    this.admin = isAdmin === 1;
    this.sortingOrder = sortingOrder;

    if (balance === undefined || isNaN(Number(balance))) {
      console.error("Balance is undefined/isNaN! " + balance);
      balance = 434343;
    }

    this.balance = Number(balance);
    this.active = Boolean(active);
  }

  setOrder(value: number | string) {
    this.sortingOrder = Number(value);
  }

  getOrder(): number {
    return this.sortingOrder;
  }

  getBalance(): number {
    if (!this.balance) {
      return 0;
    }

    return this.balance;
  }

  isOwner(): boolean {
    return this.owner;
  }

  isAdmin(): boolean {
    return this.admin;
  }

  isActive(): boolean {
    return this.active;
  }

  getMemberDeepCopy() {
    const memberObject = JSON.parse(JSON.stringify(this));

    //TODO updated this. Not all parameters are correct!
    //@ts-ignore
    return new Member(
      memberObject.owner,
      memberObject.admin,
      memberObject.balance,
      memberObject.user_code,
      memberObject.name,
      memberObject.firstName,
      memberObject.lastName,
      memberObject.email,
      memberObject.phone,
      memberObject.userImageUrl,
      memberObject.userImageAltText
    );
  }
}

/**
 * TODO Create setters, so you can change the group info.
 * This class is used to get all the authentication
 */
export default class Group extends GroupPreview {
  // Get from the server.

  activeMembers: Array<Member> = []; // DONT use this for authentication!
  users: Array<Member> = []; // new

  user_codes: Array<String> = [];

  plugin_codes: Array<String> = [];
  totalStockMoney: number = 0;
  groupWallet: number = 0;

  // Generated
  user_objects: Array<Member> = [];
  tallyPlugin?: PluginTally;

  tabletFromGroople: boolean = false;
  groupWantToBuyTablet: boolean = false;

  postalCode?: string;
  address?: string;
  city?: string;

  async initGroup(group_code: string) {
    // @ts-ignore
    await Group.post("/api/v2/groups/getGroup", { groupUUID: group_code })
      .then(async (res: any) => {
        const group = res.data.group;

        this.initMembers(group.groupMembers);

        // Gotten from the server.
        this.avatar = group.groupImageUrl;
        this.address = group.address;
        this.name = group.groupName;
        this.totalStockMoney = group.totalStockMoney;
        this.groupWallet = group.groupWallet;

        this.tabletFromGroople = Boolean(group.hasTabletFromTallyTap);
        this.groupWantToBuyTablet = Boolean(group.wantToBuyTablet);
        this.postalCode = group.postalCode;

        if (group.groupPlugins) {
          for (const index in group.groupPlugins) {
            this.plugin_codes.push(group.groupPlugins[index]);
          }
        } else {
          console.warn("Group plugin object was undefined!");
        }

        this.tallyPlugin = await PluginTally.createApp(this.group_code);
      })
      .catch((err: any) => {
        console.error(err);
        alert("Kon groep niet laden. ");

        return Promise.reject(err);
      });

    // await this.getUserBalances();

    return Promise.resolve();
  }

  initMembers(members: any[]) {
    this.users = [];

    for (const index in members) {
      const member = members[index];
      this.user_codes.push(member.userUUID);

      const userObject = new Member(
        member.owner,
        member.admin,
        member.balance,
        member.userUUID,
        member.name,
        member.firstName,
        member.lastName,
        member.email,
        member.phone,
        member.userImageUrl,
        member.userImageAltText,
        member.sortingOrder,
        member.active
      );

      this.users.push(userObject);

      if (member.active) {
        this.activeMembers.push(member.userUUID);
      }
    }

    return Promise.resolve();
  }

  wantToBuyTablet() {
    return this.groupWantToBuyTablet;
  }

  setWantToBuyTablet(boolean: boolean) {
    this.groupWantToBuyTablet = Boolean(boolean);
  }

  hasTabletFromTallyTap() {
    return this.tabletFromGroople;
  }

  getGroupWallet() {
    console.error("Deze functie is uitgeschakeld. ");
    return this.groupWallet;
  }

  getGroupCode(): string {
    return this.group_code;
  }

  getTotalStockMoney() {
    console.error("Deze functie is uitgeschakeld. ");
    return this.totalStockMoney;
  }

  getPostalCode() {
    return this.postalCode;
  }

  /**
   *
   * @param group_name
   * @return {Promise<Promise<never>>}
   */
  static async createNewGroup(group_name: String) {
    if (!group_name || group_name.length < 4)
      return Promise.reject("Naam moet minimaal 4.3 characters lang zijn.");

    if (group_name.length >= 14) {
      return Promise.reject("Naam maximaal 13 characters lang zijn. ");
    }

    // @ts-ignore
    return await Group.post("/api/v2/groups/new", { groupName: group_name })
      .then((res: any) => {
        return Promise.resolve(res.data.groupUUID);
      })
      .catch((err: any) => {
        if (err.response && err.response.data) {
          return Promise.reject(err.response.data.message);
        }

        return Promise.reject(err.toString());
      });
  }

  /**
   * Create group and return initialized group.
   * @param group_code
   * @return {Promise<Group>}
   */
  static async CreateGroup(group_code: string) {
    const group = new Group(group_code);
    await group.initGroup(group_code);
    return group;
  }

  setSound(boolean: boolean) {
    localStorage.setItem("sound", Number(boolean).toString());
  }

  setAncienniteit(boolean: boolean) {
    localStorage.setItem("ancienniteit", Number(boolean).toString());
  }

  getGroupSettings(): { sound: boolean; ancienniteit: boolean } {
    return {
      sound: Boolean(Number(localStorage.getItem("sound"))),
      ancienniteit: Boolean(Number(localStorage.getItem("ancienniteit"))),
    };
  }

  /**
   *
   * @return {Promise<Promise<any>|Promise<never>>}
   */
  getUserCodes(): Array<String> {
    return this.user_codes;
  }

  getUserObjects(): Array<Member> {
    return this.users;
  }

  getUserObjectsDeepCopy(): Array<Member> {
    const returnArray = [];

    for (const index in this.users) {
      returnArray.push(this.users[index].getMemberDeepCopy());
    }

    return returnArray;
  }

  async getBalanceTransactions(
    amount: number,
    filterSource: string,
    filterProductUUID: string,
    filterStartDate: Moment,
    filterEndDate: Moment,
    userUUID: string
  ) {
    if (!amount || isNaN(amount)) {
      amount = 20;
    }

    // console.log({
    //     groupUUID: this.group_code,
    //     filter: filterSource,
    //     startDate: filterStartDate,
    //     endDate: filterEndDate,
    //     productGroupUUID: filterProductUUID,
    //     userUUID: userUUID,
    // })
    // @ts-ignore
    return await Group.post("/api/v2/groups/getTransactions/" + amount, {
      groupUUID: this.group_code,
      filter: filterSource,
      startDate: filterStartDate,
      endDate: filterEndDate,
      productGroupUUID: filterProductUUID,
      userUUID: userUUID,
    })
      .then((res: any) => {
        const transactionsFetched = res.data.transactions;

        const transactionsLoc = [];

        for (const index in transactionsFetched) {
          const transaction = transactionsFetched[index];

          transactionsLoc.push(new TransactionClass(transaction));
        }

        return Promise.resolve(transactionsLoc);
      })
      .catch((errorMessage: any) => {
        return Promise.reject(errorMessage);
      });
  }

  async getStatistics(
    productGroupUUID: string,
    startDate: string | Moment,
    endDate: string | Moment
  ) {
    return await import("moment")
      .then((module) => module.default)
      .then(async (moment) => {
        const dateFormat = "YYYY-MM-DD";

        if (startDate) {
          startDate = moment(startDate).format(dateFormat);
        } else {
          startDate = moment("2020-01-01", "YYYY-MM-DD").format("YYYY-MM-DD");
        }

        if (endDate) {
          endDate = moment(endDate).format(dateFormat);
        } else {
          endDate = moment();
        }

        // @ts-ignore
        return await Group.post("/api/v2/groups/getStatistics/", {
          groupUUID: this.group_code,
          productGroupUUID: productGroupUUID,
          startDate: startDate,
          endDate: endDate,
        })
          .then((res: any) => {
            let statistics: {
              totalPerUser: {
                productGroupUUID: Array<String>;
                xAxis: Array<String>;
                yAxis: Array<String>;
              };
              cumulativePerWeekTotal: {
                xAxis: Array<Moment>;
                yAxis: Array<String>;
              };
              cumulativePerWeekTotalPerPerson: {
                xAxis: Array<String>;
                yAxis: Array<{
                  member: { name: String; userUUID: string };
                  yAxis: Array<BigInt>;
                }>;
              };
            } = res.data;
            // @ts-ignore
            statistics.totalPerUser.yAxis = statistics.totalPerUser.yAxis.map(
              (string) => Number(string)
            );
            // @ts-ignore
            statistics.cumulativePerWeekTotal.xAxis =
              statistics.cumulativePerWeekTotal.xAxis.map((date) =>
                moment(date, "YYYYWW").format("YYYY-MM-DD")
              );
            // @ts-ignore
            statistics.cumulativePerWeekTotal.yAxis =
              statistics.cumulativePerWeekTotal.yAxis.map((value) =>
                Number(value)
              );

            statistics.cumulativePerWeekTotalPerPerson.xAxis =
              statistics.cumulativePerWeekTotalPerPerson.xAxis.map((date) =>
                moment(date.toString(), "YYYYWW").format("YYYY-MM-DD")
              );

            // Make an array a cumulative sum.
            const cumulativeSum = (sum: any) => (value: any) => {
              return (sum = Number(sum) + Number(value));
            };

            statistics.cumulativePerWeekTotalPerPerson.yAxis.forEach(
              (object, index) => {
                // Make fromt he not cumulative data cumulative data.
                // @ts-ignore
                statistics.cumulativePerWeekTotalPerPerson.yAxis[index].yAxis =
                  object.yAxis.map(cumulativeSum(0));
              }
            );

            return Promise.resolve(statistics);
          })
          .catch((err: any) => {
            if (err) {
              return Promise.reject(err.toString());
            }

            return Promise.reject(err);
          });
      });
  }

  async editGroup(
    groupName: string,
    groupDescription: string,
    groupImageUrl: string,
    address: string,
    street: string,
    streetNumber: string,
    postalCode: string,
    city: string,
    wantToBuyTablet: boolean,
    cellPhoneNumber: string,
    streetNumberAddition: string
  ) {
    return await Group.put("/api/v2/groups/edit", {
      groupUUID: this.group_code,
      groupName: groupName,
      groupDescription: groupDescription,
      groupImageUrl: groupImageUrl,
      address: address,
      street: street,
      streetNumber: streetNumber + streetNumberAddition,
      postalCode: postalCode,
      city: city,
      wantToBuyTablet: wantToBuyTablet,
      telephone: cellPhoneNumber,
    })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  /**
   * @return {Promise<String>}
   */
  getCity(): string | undefined {
    return this.city;
  }

  async editAncienniteit(
    orderUsers: Array<{
      memberUUID: String;
      sortingOrder: number;
      isAdmin: boolean;
      active: boolean;
    }>
  ) {
    if (orderUsers.length !== this.users.length) {
      return Promise.reject({ message: "Input niet even lang", ignore: true });
    }

    const listChanges = orderUsers.filter((order, index) => {
      return order.memberUUID !== this.users[index].getUserCode();
    });

    if (listChanges.length === 0) {
      return Promise.reject({
        message: "Volgorde is het zelfde",
        ignore: true,
      });
    }

    return await Group.put("/api/v2/groups/editGroupMembers", {
      groupUUID: this.group_code,
      arrGroupMembers: orderUsers,
    })
      .then(() => {
        for (const index in this.users) {
          const user: Member = this.users[index];

          const newOrder = orderUsers.filter((object) => {
            return object.memberUUID === user.getUserCode();
          });

          if (newOrder.length === 1) {
            user.setOrder(newOrder[0].sortingOrder);
          } else {
            alert("Fout. ");
            console.error("Order not correct saved locally.");
          }

          this.users.sort((a, b) => {
            return a.getOrder() - b.getOrder();
          });
        }
      })
      .catch((err) => Promise.reject({ message: err, ignore: false }));
  }

  /**
   *
   * @param transaction_code
   * @return {Promise<Promise<any>|Promise<never>>}
   */
  async removeTransaction(transaction_code: string) {
    return Promise.reject("Not yet implemented. ");
  }

  /**
   *
   * @return {Promise<Promise<Array>|Promise<Promise<Array>|Promise<never>>>}
   */
  getAllUserObjects(): Array<Member> {
    return this.users;
  }

  /**
   *
   * @return {Promise<Promise<any>|Promise<never>>}
   */
  static async addUserToGroup(
    group_code: string,
    email: string,
    invite_code: string
  ) {
    // @ts-ignore
    return await Group.post("/api/v2/groups/addMember", {
      groupUUID: group_code,
      email: email,
      inviteCode: invite_code,
    })
      .then((res: any) => {
        return Promise.resolve(res);
      })
      .catch((err: any) => {
        return Promise.reject(err.response.data.message);
      });
  }

  /**
   *
   * @return {Promise<Promise<any>|Promise<never>>}
   */
  async removeUserFromGroup(): Promise<string> {
    return Promise.reject("Function is not implemented");
  }

  async getUserBalances() {
    // @ts-ignore
    return await Group.post("/mp/v1/tally/v2/balance/getAll", {
      groupUUID: this.group_code,
    })
      .then(async (res: any) => {
        const userBalances = res.data.userBalances;

        for (const index in userBalances) {
          const userBalance = userBalances[index];

          const user = this.users.filter(
            (user) => user.getUserCode() === userBalance.userUUID
          );

          if (user.length !== 1) {
            return Promise.reject(
              "Could not get balance: There is a mis match between balance and group users. "
            );
          }

          // await user[0].setBalance(userBalance.balance)
          //     .catch((err: any) => {
          //         return Promise.reject(err)
          //     })
        }

        return Promise.resolve(res.data.userBalances);
      })
      .catch((err: any) => {
        console.error(err);
        return Promise.reject(err);
      });
  }

  async settleBalance(
    memberUUIDs: string[],
    memberAddedMoney: number[],
    description: string,
    totalTransactionMoney: number
  ) {
    memberAddedMoney = memberAddedMoney.map((num) => Number(num));

    if (memberAddedMoney.filter((money) => isNaN(money)).length > 0) {
      return Promise.reject("Input is geen getal. ");
    }

    if (memberUUIDs.length !== memberAddedMoney.length) {
      return Promise.reject("Input invalid: length not the same. ");
    }

    const transactions = [];

    for (const index in memberUUIDs) {
      if (memberAddedMoney[index] !== 0) {
        transactions.push({
          memberUUID: memberUUIDs[index],
          totalMoney: memberAddedMoney[index],
        });
      }
    }

    // console.log("Sernding following object: ")
    // console.log({
    //     groupUUID: this.group_code,
    //     timestamp: Date(),
    //     arrSettlements: transactions,
    //     description: description,
    //     totalMoney: totalTransactionMoney,
    // });

    //@ts-ignore
    return await Group.post("/mp/v1/tally/v2/balance/settlements", {
      groupUUID: this.group_code,
      timestamp: Date(),
      arrSettlements: transactions,
      description: description,
      totalTransactionMoney: totalTransactionMoney,
    })
      .then((res: any) => {
        return Promise.resolve();
      })
      .catch((err: any) => {
        if (err.response && err.response.data) {
          return Promise.reject(err.response.data.message);
        }

        return Promise.reject(err.message);
      });
  }

  async createInviteLink() {
    // @ts-ignore
    return await Group.post("/api/v2/groups/invite", {
      groupUUID: this.group_code,
    })
      .then((res: any) => {
        const inviteCode = res.data.inviteCode;

        const inviteLink = new URL(
          ENV.websiteURL.WEBSITE_URL +
            "/groups/invited/" +
            this.group_code +
            "/" +
            inviteCode
        );

        return Promise.resolve(inviteLink);
      })
      .catch((err: any) => {
        console.error(err);

        if (err.response && err.response.data) {
          return Promise.reject(err.response.data.message);
        }
        return Promise.reject(err.toString());
      });
  }

  static async getPublicGroupInfo(group_code: string) {
    // @ts-ignore
    return await Group.post("/api/v2/groups/pub/getGroupPub", {
      groupUUID: group_code,
    })
      .then((res: any) => {
        const groupMembers = res.data.groupMembers;
        const group = res.data.group;

        const groupMemberClass: Array<PublicMember> = [];

        for (const index in groupMembers) {
          const member = groupMembers[index];
          groupMemberClass.push(
            new PublicMember(
              member.userUUID,
              member.owner,
              member.admin,
              member.isGroupMaster,
              member.active,
              member.name,
              member.firstName,
              member.invited,
              member.invitationAccepted,
              member.userImageUrl,
              member.userImageAltText
            )
          );
        }

        return Promise.resolve({
          group: group,
          groupMembers: groupMemberClass,
        });
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
}

export class PublicMember {
  userUUID: string;
  owner: boolean = false;
  admin: boolean = false;
  isGroupMaster: boolean;
  active: boolean;
  name: string;
  firstName: string;
  invited: boolean;
  invitationAccepted: boolean;
  userImageUrl: URL;
  userImageAltText: string;

  constructor(
    userUUID: string,
    isOwner: boolean,
    isAdmin: boolean,
    isGroupMaster: boolean,
    active: boolean,
    name: string,
    firstName: string,
    invited: boolean,
    invitationAccepted: boolean,
    userImageUrl: string,
    userImageAltText: string
  ) {
    this.userUUID = userUUID;
    this.owner = isOwner;
    this.admin = isAdmin;
    this.isGroupMaster = isGroupMaster;
    this.active = active;
    this.name = name;
    this.firstName = firstName;
    this.invited = invited;
    this.invitationAccepted = invitationAccepted;

    console.warn("Custom URL created here");

    if (!userImageUrl) {
      this.userImageUrl = DEFAULT_PROFILE_IMAGE;
    } else {
      this.userImageUrl = new URL(ENV.apiURL.SERVER_URL + "/" + userImageUrl);
    }

    this.userImageAltText = userImageAltText;
  }

  getFirstName() {
    return this.name;
  }

  getImageUrl() {
    return this.userImageUrl;
  }

  getImageAltText() {
    return this.userImageAltText;
  }
}
