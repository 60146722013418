import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import Title from "../components/text/Title";
// @ts-ignore
import {NavLink} from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// @ts-ignore
import SwipeableViews from 'react-swipeable-views';
import {Device, DeviceInfo} from "@capacitor/core";

const SUB_TITLE = 'Helemaal gratis, toegankelijk op al je apparaten mét supermarkt vergelijker!';


export default function StartPage() {

    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | {}>({});

    useEffect(() => {
        Device.getInfo().then(info => {
            setDeviceInfo(info);
        })
    }, []);


    return (
        <div className="text-center start-page">

            <Row className='bg-white'>
                <div className='col-12 col-md-6 '>
                    <Header>Dé digitale Turflijst</Header>
                    <div className='d-none d-md-block center'>
                        <div>{SUB_TITLE}</div>
                        <NavLink
                            className="btn btn-primary btn-margin-top"
                            to={"./register"}>
                            <div className=''>Registeer hier</div>
                        </NavLink>
                    </div>
                </div>
                <div className='col-12 col-md-6'>
                    <img
                        style={{width: '100%'}}
                        className='giff'
                        alt={'giff uitleg video'}
                        src={require("../../assets/lancerings video kort.gif")}
                    />
                </div>
                <div className='d-md-none col-12 btn-margin-bottom '>
                    <div className='col-12 btn-margin-top'>{SUB_TITLE}</div>
                    <NavLink
                        className="btn btn-primary btn-margin-top"
                        to={"./register"}>
                        <div className=''>Registeer hier</div>
                    </NavLink>
                </div>
            </Row>

            <Row>
                <ItemList>
                    <Title>Beste deals</Title>
                    <div>
                        Vergelijk de prijzen van alle grote supermarkten!
                    </div>
                    <div>
                        Dus bespaar geld door TallyTap te gebruiken!
                    </div>
                </ItemList>
                <ItemList>
                    <Title className='no-gutters no-padding'>Helemaal gratis</Title>
                    <div>
                        Je kan bij ons een tablet aanvragen, maar ook je eigen
                        tablet ophangen. De tablet wordt gratis geïnstalleerd bij jou thuis.

                    </div>
                    <div>Super handig!</div>
                </ItemList>
                <ItemList>
                    <Title>Alles op de turflijst</Title>
                    <div>
                        Pils, speciaal bier en koffie op de turf? Voeg toe wat je maar wilt!
                    </div>
                </ItemList>
            </Row>
            {/*@ts-ignore*/}
            <Row className={'bg-white text-center '.concat(( 'ios' === deviceInfo.operatingSystem || deviceInfo.isVirtual) ? ' hidden ' : '')}>
                <Header>Download de app!</Header>
                <div className='col-12 btn-margin-bottom'>
                    TallyTap is overal toegankelijk. Wanneer je bier bijna opraakt krijgt een notificatie en kan je het direct bij bestellen.
                </div>
                    <a
                        className='col-6 '
                        href="https://play.google.com/store/apps/details?id=io.ionic.Groople">
                        <img
                            className='download-app apple'
                            style={{width: '100%'}}
                            src={require("../../assets/google-play-store.png")}
                            alt='Download Android playstore'
                        />
                    </a>
                    <a
                        href={'https://apps.apple.com/us/app/tallytap/id1554650334'}
                        className='col-6 '
                        style={{width: "10"}}
                    >
                        <img
                            className='download-app apple'
                            style={{width: '100%'}}
                            src={require("../../assets/apple-app-store.png")}
                            alt='Download Apple appstore'
                        />
                    </a>
            </Row>
            {/*<Row className='bg-white'>*/}
            {/*    <Header>Hoe werkt het?</Header>*/}
            {/*    <HowToStepper/>*/}
            {/*</Row>*/}
        </div>
    );
}

function Row({children, className} : {children? : any, className?: string}) {
    return (
        <div className={className}>
            <div className='container'>
                <div className='area row'>
                    {children}
                </div>
            </div>
        </div>
    )
}

function Header(props : any) {
    return (
        <div {...props} className={' title-landing-page lead col-12 ' + props.className} style={{padding: 0}}>
            {props.children}
        </div>
    )
}

function HowToStepper(props : any) {

    const [activeStep, setActiveStep] = React.useState(0);

    const tutorialSteps = [
        {
            label: 'San Francisco – Oakland Bay Bridge, United States',
            imgPath:
                'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
        },
        {
            label: 'Bird',
            imgPath:
                'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
        },
        {
            label: 'Bali, Indonesia',
            imgPath:
                'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
        },
        {
            label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
            imgPath:
                'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60',
        },
        {
            label: 'Goč, Serbia',
            imgPath:
                'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
        },
    ];


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <div className='col-12'>
            <Paper square elevation={0}>
                <Typography>{tutorialSteps[activeStep].label}</Typography>
            </Paper>
            <SwipeableViews
                axis={'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {tutorialSteps.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img src={step.imgPath} alt={step.label}/>
                        ) : null}
                    </div>
                ))}
            </SwipeableViews>
            <div onClick={() => {
                handleNext()
            }}>
                Volgende
            </div>
            <MobileStepper
                steps={tutorialSteps.length}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                    <div
                        className='mobile-stepper-button next'
                        onClick={() => {
                            if (activeStep !== tutorialSteps.length - 1) {
                                handleNext();
                            }
                        }}
                    >
                        <i className="center fas fa-chevron-right"/>
                    </div>
                }
                backButton={
                    <div
                        className='mobile-stepper-button previous'
                        onClick={() => {
                            if (activeStep !== 0) {
                                handleBack();
                            }
                        }}>
                        <i className="center fas fa-chevron-left"/>
                    </div>
                }
            />
        </div>
    )
}

function ItemList({children} : any) {
    return (
        <div className='col col-md-4 col-12 text-center col--nb'>
            {children}
        </div>
    )
}

function SubTitle(props: any) {
    return (
        <div className={"sub-title " + props.className} {...props}>
            {props.children}
        </div>
    )
}

function FAQ() {

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <Title>Veel gestelde vragen</Title>
            <List>
                <ListItem button onClick={handleClick}>
                    {/*<ListItemIcon>*/}
                    {/*    <InboxIcon />*/}
                    {/*</ListItemIcon>*/}
                    {/*<ListItemText primary="Inbox" />*/}
                    {open ? "OPEN" : "CLOSE"}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button>
                            {/*<ListItemIcon>*/}
                            {/*    <StarBorder />*/}
                            {/*</ListItemIcon>*/}
                            {/*<ListItemText primary="Starred" />*/}
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </div>
    )
}