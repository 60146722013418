import moment, {Moment} from "moment";

export enum Company {
    AH = 'Albert Heijn',
    PLUS = 'PLUS',
    JUMBO = 'Jumbo',
    COOP = 'COOP',
    DRANKDOZIJN = 'Drankdozijn',
}

export const BeerCategories = [
    'Abdijbier',
    'AmberAle',
    'Blond',
    'BruinDonker',
    'Champagnebier',
    'Cider',
    'Fruitbier',
    'Geuze',
    'IPA',
    'Pilsner',
    'Porter',
    'Radler',
    "Saison",
    "Trappistenbier",
    "Tripel",
    "Winterbier",
    "WitbierWeizenbier"
];

export const AlcoholFreeCategories = ['Alcoholvrij', 'Non-Alcoholic Spirit'
];

export const StrongLiquor = [
    'Absint',
    'Armagnac',
    'Baijiu',
    'Bitterlikeur',
    'Bitters',
    'Blended Malt',
    'Bourbon Whiskey',
    'Brandy',
    'Cachaa',
    'Calvados',
    'Cognac',
    'Dark Rum',
    'Eau-de-Vie',
    'Flavoured Rum',
    'Flavoured Vodka',
    'Fruitjenever',
    'Gin',
    'Gold Rum',
    'Grain Whisky',
    'Grappa',
    'Jonge Jenever',
    'Limoncello',
    'Mezcal',
    'Mix',
    'Moonshine',
    'Oude Jenever',
    'Overproof Rum',
    'Pisco',
    'Pot Still',
    'Premium Vodka',
    'Raki',
    'Rum likeur',
    'Rye Whisky',
    'Sambuca',
    'Sangria',
    'Single Malt',
    'Siropen',
    'Stout',
    'Tennessee Whiskey',
    'Tequila',
    'Tonic',
    'Vermout',
    'Vieux',
    'Vodka',
    'Vodka likeur',
    'Vruchtensappen',
    'Whisky likeur',
    'White Rum',
];

export const wineCategories = [
    'Cava',
    'Champagne',
    'Dessertwijn',
    'Dubbel',
    'Korenwijn',
    'Mousserend',
    'Port',
    'Prosecco',
    'Rood',
    'Rose',
    'Sake',
    'Sherry',
    'Vruchtenwijn',
    'Wit',

];
export class Bonus {

    get type(): string {
        return this._type;
    }

    get startDate(): Moment {
        return this._startDate;
    }

    get endDate(): Moment {
        return this._endDate;
    }

    get description(): string {
        return this._description;
    }

    get salePrice(): number {
        return this._salePrice;
    }

    get productInSale(): boolean {

        return this._productInSale
    }

    constructor(
        private _productInSale: boolean,
        private _type: string,
        private _startDate: Moment,
        private _endDate: Moment,
        private _description: string,
        private _salePrice: number
    ) {

        if (_productInSale) {
            this._productInSale = this.endDate.isAfter(moment());
        }

    };
}

export default class ProductCartItem {

    constructor(
        private _company: Company,
        private _name: String,
        private _description: string,
        private _brand: string,
        private _price: number,
        private _categoryGroup: string,
        private _categorySubGroup: string,
        private _categoryProductGroup: string,
        private _bonus: Bonus,
        private _productLink: string,
        private _imageLink: string,
        private _selectedAmount: number,
        private _volume: string,
    ) {
    };

    get volume(): any {
        return this._volume;
    }

    /**
     * Amount of times the user wants to buy this item.
     * @param value
     */
    set selectedAmount(value: number) {
        this._selectedAmount = value;
    }

    get selectedAmount(): number {
        return this._selectedAmount;
    }

    get company(): Company {
        return this._company;
    }

    get name(): String {
        return this._name;
    }

    get description(): string {
        return this._description;
    }

    get brand(): string {
        return this._brand;
    }

    get price(): number {
        return this._price;
    }

    get categoryGroup(): string {
        return this._categoryGroup;
    }

    get categorySubGroup(): string {
        return this._categorySubGroup;
    }

    get categoryProductGroup(): string {
        return this._categoryProductGroup;
    }

    get bonus(): Bonus {

        return this._bonus
    }

    get productLink(): string {
        return this._productLink;
    }

    get imageLink(): string {
        return this._imageLink;
    }

    set company(value: Company) {
        this._company = value;
    }

    set name(value: String) {
        this._name = value;
    }

    set description(value: string) {
        this._description = value;
    }

    set brand(value: string) {
        this._brand = value;
    }

    set price(value: number) {
        this._price = value;
    }

    set categoryGroup(value: string) {
        this._categoryGroup = value;
    }

    set categorySubGroup(value: string) {
        this._categorySubGroup = value;
    }

    set categoryProductGroup(value: string) {
        this._categoryProductGroup = value;
    }

    set bonus(value: Bonus) {
        this._bonus = value;
    }

    set productLink(value: string) {
        this._productLink = value;
    }

    set imageLink(value: string) {
        this._imageLink = value;
    }
}

export class ProductCartItemAH extends ProductCartItem {
    constructor(
        _company: Company,
        _name: String,
        _description: string,
        _brand: string,
        _price: number,
        _categoryGroup: string,
        _categorySubGroup: string,
        _categoryProductGroup: string,
        _bonus: Bonus,
        _productLink: string,
        _imageLink: string,
        _selectedAmount: number,
        _volume: string,
        private _systeemnr: string,
    ) {
        super(
            _company,
            _name,
            _description,
            _brand,
            _price,
            _categoryGroup,
            _categorySubGroup,
            _categoryProductGroup,
            _bonus,
            _productLink,
            _imageLink,
            _selectedAmount,
            _volume
        );
    }

    get systeemnr(): string {
        return this._systeemnr;
    }
}