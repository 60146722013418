import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Loading from "../components/Loading";
import HeaderPublic from "../../global/navbar/public/Header";
import StartPage from "../landing-page/LandingPage";
import Footer from "../../global/navbar/footer/Footer";

const TermsAndConditions = lazy(() => import( "../TermsAndConditions"));
const BruikleenOvereenkomst = lazy(() => import( "../BruikleenOvereenkomst"));
const LoginWrapper = lazy(() => import("../../global/login/Login.web"));
const InvitedByCode = lazy(() => import("../../group/settings/InvitedByCode"));
const Error = lazy(() => import("../pageNotFound/Error.web"));


/**
 * Navigation when you are logged out
 * @property loginUser
 */
export default function UserLoggedOut_Render() {

    return (
        <BrowserRouter>
            <Suspense fallback={<Loading/>}>
                <HeaderPublic/>
                <div className="content-underneath-navbar">
                    <Switch>
                        <Route exact path="/" component={StartPage}/>
                        <Redirect exact from="/account" to=""/>
                        <Route exact path="/login" component={LoginWrapper}/>
                        <Route exact path="/register" component={() => <LoginWrapper register/>}/>
                        <Route exact path="/faq" component={Faq}/>
                        <Route exact path="/terms" component={TermsAndConditions}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path='/bruikleen-overeenkomst' component={BruikleenOvereenkomst}/>
                        <Route path="/groups/invited/:group_code/:invite_code" component={InvitedByCode}/>
                        <Route component={Error}/>
                    </Switch>
                </div>
                <Footer/>
            </Suspense>
        </BrowserRouter>
    )
}


class Faq extends React.Component {
    render() {
        return (
            <div style={{minHeight: "100%", position: "relative", backgroundColor: "orange"}}>
                FAQ
            </div>
        )
    }
}

function About() {
    return (
        <div>About page</div>
    )
}
