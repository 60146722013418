import env from "../../global/config/environment";
import axois from "axios/index";


/**
 * Abstract class for Apps.
 * @abstract
 */
export default class AppAPI {
    app_code;

    constructor(app_code) {
        this.app_code = app_code;
    }


    /**
     *
     * @param route
     * @param config
     * @return {Promise<AxiosResponse<T>>}
     */
    static get(route, config) {

        // console.info("getting from route: " + env.apiURL.SERVER_URL + route);

        if (!route) {
            return Promise.reject("Route cant be undefined or empty!")
        }

        return axois.get(env.apiURL.SERVER_URL + route).then((res) => {
            return Promise.resolve(res)
        }).catch((err) => {
            return Promise.reject(err);
        })


    }

    static post(route, params, config) {

        // console.info("Posting from route: " + ENV.apiURL.SERVER_URL + route);
        // console.info("Posing with params: ");
        // console.info(params);

        if (!route) {
            return Promise.reject("Route cant be undefined or empty!")
        }

        if (!params) {
            params = {};
        }

        return axois.post(env.apiURL.SERVER_URL + route, params)
            .then((res) => {
                // console.info(res);

                return Promise.resolve(res);
            })
            .catch((err) => {
                console.error(err);
                return Promise.reject(err)
            })

    }
}