// These must be the first lines in src/index.js
// import 'core-js/es/map';
// import 'core-js/es/set';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import userStore from "./global/contexts/Stores";
import {StoreProvider} from "easy-peasy";
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <StoreProvider
        store={userStore}>
        <App/>
    </StoreProvider>, document.getElementById('root')
);

// serviceWorker.register();


