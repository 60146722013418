import React from 'react';

export default function TallyTap({className, style, type, src, alt}) {

    if (!src) {
        src = require("../../../assets/logo/tallytap-logo-v1-transparent-230x65.JPG");
        alt = "TallyTap logo "
    }

    if (!style) {
        style = {maxWidth: 230}
    }

    return (
        <img
            className={className}
            style={style}
            src={src}
            alt={alt}
        />
    )
}