import userModel from "./models/userModel";
import {createStore} from "easy-peasy";


export function setCurrentUser(user) {
    return {
        type: "SET_CURRENT_USER",
        user
    };
}

export default createStore(userModel);



