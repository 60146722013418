import React from 'react';
import {NavLink} from "react-router-dom";
import "./Footer.css"

export default function Footer(props) {

    const style = {
        color: "grey",
    };


    return (
        <div className="card-footer text-muted custom-footer  ">
            <nav className="navbar navbar-default" style={{zIndex: 1}}>
                <NavLink style={style} to="/about">Over TallyTap</NavLink>
                <NavLink style={style} to="/faq">Support</NavLink>
                <NavLink style={style} to="/terms">Algemene Voorwaarden</NavLink>
                {/*<NavLink style={style} to="/privacy-statement">Privacy Statement</NavLink>*/}
                {/*<NavLink style={this.state.style} to="/suggest-feature">Ik mis een feature!</NavLink>*/}
            </nav>
        </div>
    )

}
