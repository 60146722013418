import Database from "../../../../global/config/Database";
import ENV from "../../../../global/config/environment";
import ProductCartItem, {
    BeerCategories,
    Bonus,
    Company,
    ProductCartItemAH,
    wineCategories
} from "../react/groceries/ProductCartItem"
import moment from "moment";

interface TotalConsumed {
    userUUID: string,
    sumQuantity: number,
    sumTotalMoney: number,
    name: string
}

interface StockBalance {
    userUUID: string,
    qtyPurchased: number,
    qtyConsumed: number,
    qtyBalance: number
}

export default class ProductGroup extends Database {

    standardPrice: number;
    averagePrice: number;
    totalStockQuantity: number;
    totalStockMoney: number;
    active: number = 0;
    productGroupName: string;
    productGroupImageUrl: string;
    productGroupImageAltText: string = "";
    description: string = "";
    group_code: string;
    productGroupType: string;
    totalConsumed: TotalConsumed[] = [];
    stockMoneyDifference: number = 0;

    // These variables are not yet in the database.
    product_code: string;
    notification_amount: number = Number.MAX_VALUE;
    wanted_in_stock_amount: number = Number.MIN_VALUE;
    stockBalance: StockBalance[] = [];


    constructor(
        // standardPrice: number,
        // averagePrice: number,
        totalStockQuantity: number,
        // totalStockMoney: number,
        groupUUID: string,
        active: number,
        productGroupName: string,
        productGroupImageUrl: string,
        productGroupImageAltText: string,
        description: string,
        productGroupUUID: string,
        productGroupType: string,
        totalConsumed: TotalConsumed[],
        stockMoneyDifference: number,
        stockBalance: StockBalance[],
    ) {
        super();


        const checkArgs = [
            totalStockQuantity,
            groupUUID,
            active,
            productGroupName,
            // productGroupImageUrl,
            // productGroupImageAltText,
            // description,
            productGroupUUID,
            // totalConsumed

        ];

        for (const index in checkArgs) {
            if (checkArgs[index] === undefined) {
                console.error(checkArgs);
                throw new Error("Argument is undefined: " + checkArgs[index] + " index: " + index);
            }
        }

        if (productGroupImageUrl === null) {
            productGroupImageUrl = "";
        }

        if (productGroupImageUrl.charAt(0) === "u") {
            productGroupImageUrl = ENV.apiURL.SERVER_URL + "\\" + productGroupImageUrl;
        }

        this.standardPrice = 0;
        this.averagePrice = 0;
        this.totalStockQuantity = totalStockQuantity;
        this.totalStockMoney = 0;
        this.group_code = groupUUID;
        this.active = active;
        this.productGroupName = productGroupName;
        this.productGroupImageUrl = productGroupImageUrl;
        this.productGroupImageAltText = productGroupImageAltText;
        this.description = description;
        this.product_code = productGroupUUID;
        this.productGroupType = productGroupType;
        this.totalConsumed = totalConsumed;
        this.stockMoneyDifference = stockMoneyDifference;
        this.stockBalance = stockBalance;
    }

    getStockBalance(userUUID: string) {
        return this.stockBalance.filter((element) => {
            return element.userUUID === userUUID;
        }).map(element => {
            return element.qtyBalance
        })
    }

    async addStockBalance(userUUIDs: string[], quantities: number[], description: string) {

        if (quantities.reduce((a, b) => Number(a) + Number(b), 0) === 0) {
            return Promise.reject("Niks om te verwerken. ")
        }

        if (userUUIDs.length !== quantities.length) {
            return Promise.reject("Lengte userUUID en quantity array is niet gelijk. ");
        }

        const arrStockMutations: Array<{}> = [];

        for (const index in userUUIDs) {

            if (quantities[index] !== 0) {
                arrStockMutations.push({
                    productGroupUUID: this.product_code,
                    quantity: quantities[index],
                    price: 0,
                    totalMoney: 0,
                    memberUUID: userUUIDs[index]
                })
            }
        }

        // @ts-ignore
        await ProductGroup.post("/mp/v1/tally/v2/stocks/addStock",
            {
                description: description,
                groupUUID: this.group_code,
                timestamp: new Date(),
                grandTotal: 0,
                totalTransactionMoney: 0,
                arrStockMutations: arrStockMutations
            })
            .then((res: any) => {
                alert("Geupload!");
                return Promise.resolve(res);
            })
            .catch((err: any) => {
                if (err && err.response && err.response.message) {
                    return Promise.reject(err.response.message);
                }

                return Promise.reject("Onbekende error. ");
            })
    }


    async updateStandardPrice(
        standardPrice: number,
    ) {

        await ProductGroup.put("/mp/v1/tally/v2/products/editProductGroup", {
            groupUUID: this.group_code,
            productGroupUUID: this.product_code,
            standardPrice,
        })
            .catch((err) => {

                if (err && err.data) {
                    return Promise.reject(err.data.reject);
                }

                return Promise.reject(err)
            })
    }


    getStockMoneyDifference() {
        return this.stockMoneyDifference;
    }

    getStandardPrice() {
        return this.standardPrice;
    }

    setStandardPrice(value: number | string) {
        this.standardPrice = Number(value);
    }

    /**
     *
     * @param product_code
     * @return {Promise<Promise<any>|Promise<never>>}
     */
    getProductObject(product_code: string) {
        return this;
    }

    getTotalConsumed(userUUID: string) {
        const consumed = this.totalConsumed.filter((consumed) => consumed.userUUID === userUUID)

        if (consumed.length !== 1) {
            return 0
        }

        return Number(consumed[0].sumQuantity)
    }

    setTotalConsumed(userUUID: string, totalConsumed: number) {

        this.totalConsumed.map((consumed) => {
            if (consumed.userUUID === userUUID) {
                consumed.sumQuantity = totalConsumed
            }

            return null;
        })
    }


    getTotalConsumedCosts(userUUID: string) {
        const consumed = this.totalConsumed.filter((consumed) => consumed.userUUID === userUUID);

        if (consumed.length !== 1) {
            return 0;
        }

        return Number(consumed[0].sumTotalMoney).toFixed(2)
    }

    getProductGroupName(): string {
        return this.productGroupName;
    }

    getProductImage(): string {
        return this.productGroupImageUrl
    }

    getProductAltText(): string {
        return this.productGroupImageAltText
    }


    setProductName(name: string): void {
        this.productGroupName = name;
    }

    getTotalStockQuantity() {
        return this.totalStockQuantity;
    }

    getAveragePrice() {
        return this.averagePrice;
    }

    getProductCode() {
        return this.product_code;
    }


    static async createNewProduct(
        group_code: string,
        productGroupName: string,
        standardPrice: number,
        unitOfMeasurement: number,
        totalStockQuantity: number,
        productGroupImageUrl: string,
        productImageAltText: string,
        description: string,
        productImageFile: string
    ) {


        const bodyFormData = new FormData();
        bodyFormData.append("groupUUID", group_code);
        bodyFormData.append("productGroupName", productGroupName);
        bodyFormData.append("standardPrice", standardPrice.toString());
        bodyFormData.append("unitOfMeasurement", unitOfMeasurement.toString());
        bodyFormData.append("totalStockQuantity", totalStockQuantity.toString());
        bodyFormData.append("productGroupImageUrl", productGroupImageUrl);
        bodyFormData.append("productImageAltText", productImageAltText);
        bodyFormData.append("description", description);
        bodyFormData.append("image", productImageFile);


        await ProductGroup.post("/mp/v1/tally/v2/products/newProductGroup", bodyFormData, {'Content-Type': 'multipart/form-data'})
            .then(function (response) {
                //handle success
                alert("Product is toegevoegd. ");
                return Promise.resolve()
            })
            .catch(function (response) {
                //handle error

                return Promise.reject(response)
            });

    }

    getProductGroupType(supermarket: Company): string {

        switch (supermarket) {
            case Company.AH :
                return this.productGroupType;
            default:
                return this.productGroupType;
        }
    }

    getDescription(): string {
        return this.description;
    }

    async editProductGroup(
        productGroupName: string,
        productImageFile: string,
        productGroupImageAltText: string,
        description: string,
    ) {

        const bodyFormData = new FormData();
        bodyFormData.append("groupUUID", this.group_code);
        bodyFormData.append("productGroupUUID", this.product_code);
        bodyFormData.append("productGroupName", productGroupName);
        bodyFormData.append("standardPrice", "0");
        bodyFormData.append("image", productImageFile);

        return await ProductGroup.put("/mp/v1/tally/v2/products/editProductGroup", bodyFormData, {'Content-Type': 'multipart/form-data'})
    };

    async getDrankDozijn(): Promise<ProductCartItem[]> {

        // if(this.getProductGroupType(Company.DRANKDOZIJN) !== ""){
        //     return [];
        // }

        const groceries: ProductCartItem[] = [];

        return await ProductGroup.get("/api/v1/drankdozijn", {})
            .then(res => {
                const products = res.data.productsDrankdozijn;
                let filterCategories: any[] = [];

                switch (this.getProductGroupType(Company.DRANKDOZIJN)) {
                    case "CratesPils" :
                        filterCategories = [];
                        break;
                    case "Wine":
                        filterCategories = wineCategories;
                        break;
                    case "CraftBeer":
                        filterCategories = BeerCategories;
                        break;
                    default:
                        break;
                }

                const productInBonus = products.filter((item: any, index: number) => {
                    item.rrp_price = Number(item.rrp_price.replace(',', '.'));
                    item.search_price = Number(item.search_price);
                    return item.rrp_price > item.search_price && filterCategories.indexOf(item.merchant_category) !== -1
                });


                productInBonus.forEach((product: any) => {
                    const bonus = new Bonus(
                        product.rrp_price > product.search_price,
                        (100 - ((product.search_price * 100) / product.rrp_price)).toFixed(0) + '% korting',
                        moment(),
                        moment().add(1, 'd'),
                        '',
                        product.search_price
                    );

                    groceries.push(
                        new ProductCartItem(
                            Company.DRANKDOZIJN,
                            product.product_name,
                            product.description,
                            product.brand_id, // THIS IS WRONG!
                            product.rrp_price,
                            product.merchant_category,
                            product.merchant_category,
                            product.merchant_category,
                            bonus,
                            product.aw_deep_link,
                            product.merchant_image_url,
                            0,
                            "",
                        ));
                });

                return groceries;
            })
            .catch((err) => {
                console.error("Kon de producten van de COOP niet ophalen. ");
                return [];
            });
    }

    async getCoop(): Promise<ProductCartItem[]> {

        let groceries: any[] = [];

        return await ProductGroup.post("/api/v1/coop/subcategory", {subcategory: "wijn bier en sterke drank"}, {})
            .then((res) => {

                const productsCOOP = res.data.products;
                productsCOOP.forEach((product: any) => {

                    const bonus = new Bonus(
                        false,
                        product.type_korting,
                        moment(product.sales_price_effective_date),
                        moment(product.sales_price_end_date),
                        '',
                        product.sales_price
                    );

                    groceries.push(
                        new ProductCartItem(
                            Company.COOP,
                            product.product_name,
                            product.description,
                            product.brand_id, // THIS IS WRONG!
                            product.search_price,
                            product.merchant_category,
                            product.merchant_category,
                            product.merchant_category,
                            bonus,
                            product.aw_deep_link,
                            product.merchant_image_url,
                            0,
                            "0",
                        ));
                });

                return groceries
            })
            .catch((err) => {
                console.error("Kon de producten van de COOP niet ophalen. ");
                return [];
            });
    }

    async getGroceries(limit: number) {

        let groceries: any[] = [];

        if (isNaN(Number(limit))) {

            limit = 43;
        }

        // @ts-ignore
        await ProductGroup.get("/mp/v1/tally/v2/supermarket/ah/get" + this.getProductGroupType(Company.AH) + "/" + limit) // this.getProductGroupType() CratesPils,
            .then((res: any) => {

                res.data.products_AH.forEach((item: any) => {

                    const bonus = new Bonus(
                        item.bonus === "Yes",
                        item.type_korting,
                        moment(item.sales_price_effective_date),
                        moment(item.sales_price_end_date),
                        '',
                        item.sales_price
                    );

                    groceries.push(
                        new ProductCartItemAH(
                            Company.AH,
                            item.product_title,
                            item.description,
                            item.brand,
                            item.price,
                            item.ah_category,
                            item.ah_subcategory,
                            item.ah_productcategory,
                            bonus,
                            item.link,
                            item.image_link,
                            0,
                            item.hoeveelheid,
                            item.systeemnr
                        ))
                });

            })
            .catch((err: any) => {
                console.error(err);
                return Promise.reject("Call to get beer was unsuccessful! ")
            });
        return groceries;
    }


    /**
     *
     * @return {Promise<void>}
     */
    static async uploadImage(group_code: string, product_name: string, product_code: string, product_uri: string) {

        return Promise.reject("Not yet implemented with the new database. ");
    };

}