import React from "react";

interface Props {children?: any, className?: string, style?: object, onClick?: () => {}}

export default function Title({children, className, style, onClick}: Props){
    return (
        <div className={"lead " + className} style={style} onClick={() => {if(onClick){onClick()}}}>
            {children}
        </div>
    )
}