import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import "../NavigationBar.css";
import AppBar from "@material-ui/core/AppBar/AppBar";
import ENV from "../../config/environment";
import TallyTap from "../../components/logo/TallyTap";
import '../Header.css'


export function Header() {

    const [backgroundColor, setBackgroundColor] = useState("white");

    // This functions like a componentDidMount
    useEffect(() => {
        if (!ENV.apiURL.IS_THIS_LIVE_SERVER) {
            setBackgroundColor("orange");
        }
        //eslint-disable-next-line
    }, []);

    return (
        <AppBar position="sticky"
                style={{backgroundColor: backgroundColor, color: "black", minHeight: 60}}>
            <div className="navbar ">

                <NavLink activeClassName="active-nav-link" className="text-left navText col-6 col-md-6" to={"/"}>
                    <TallyTap style={{maxHeight: 40}}/>
                </NavLink>
                <div className='col-6 col-sm-4 d-flex flex-row-reverse '>
                    <NavLink to={"/login"}>
                        <div className={'text-right btn btn-primary'}>Inloggen</div>
                    </NavLink>

                    <NavLink className="d-none d-sm-block"
                             to={"/register"}>
                        <div className='btn btn-success btn-margin-right '>Registreer</div>
                    </NavLink>
                </div>
            </div>
        </AppBar>
    )

}

export default Header;