import React, {lazy, Suspense, useEffect, useState} from 'react';
import './App.css';
import "./group/Group";
// Tutorial for react-router-dom https://www.youtube.com/watch?v=XRfD8xIOroA
import {BrowserRouter, Route, Switch} from "react-router-dom";
import env from "./global/config/environment";
import Loading from "./global/components/Loading";

import {useStoreActions, useStoreState} from "easy-peasy";

import axios from "axios";
import setAuthorizationToken from "./global/config/Autorization";
import UserLoggedOut_Render from "./global/routing/UserLoggedOut";
import emptyCache from "./global/components/handleCache";
import ReactGA from 'react-ga';

import { hotjar } from 'react-hotjar';
const UserInGroup = lazy(() => import("./global/routing/UserInGroup"));
const UserNotInGroup = lazy(() => import("./global/routing/UserNotInGroup"));
const ConnectionFailed = lazy(() => import("./global/ConnetionFailed"));
const ApplicationIsUpdating = lazy(() => import("./global/UpdatingApp"));

// import GroupsOverview from "./group/GroupOverview";


function App() {

    const authenticated = useStoreState(state => state.authenticated);

    // eslint-disable-next-line no-unused-vars
    const [hasServerConnection, setHasServerConnection] = useState(true);
    const [error, setError] = useState({});
    const [updating, setUpdating] = useState(false);
    const {fetchUser} = useStoreActions(actions => actions);

    if (localStorage.jwtToken) {

        setAuthorizationToken(localStorage.jwtToken);
    }

    useEffect(() => {

        try{
            ReactGA.initialize('G-Q12MCKVSXJ');
            ReactGA.pageview("/");
        }catch (e) {
            console.error("Could not load GA: " ,e);
        }


        axios.get(env.apiURL.SERVER_URL + "/api/v2/ping?debugOn=1")
            .then(res => {


                // check for client update
                if(res.data && res.data.client && res.data.client.version ){

                    const currentClientVersion = localStorage.getItem('client_version');
                    const newestClientVersion = Number(res.data.client.version);

                    if(!currentClientVersion){
                        console.info("No previous version recognised. ");
                        localStorage.setItem('client_version', newestClientVersion);
                    }

                    if(Number(res.data.client.version) > localStorage.getItem('client_version')){
                        console.info("Client is using a old version. ");
                        emptyCache()
                            .then(() => {
                                alert("App is geupdate naar de nieuwste versie! " + newestClientVersion );
                                localStorage.setItem('client_version', newestClientVersion);
                                window.location.reload();
                            })
                    }

                    hotjar.initialize();


                } else {
                    console.error("Cannot identify if client is on the latest version. ")
                }

                localStorage.setItem("api_version", res.data.version.api);
                localStorage.setItem("db_version", res.data.version.db);

                if (Number(localStorage.api_version) < env.version.AT_LEAST_SERVER_VERSION) {
                    setUpdating(true);
                }
            })
            .catch(err => {
                setError(err);
                console.log(err);
                // setHasServerConnection(false);
            });

    }, []);


    // 2. Check if user is authenticated
    if (!hasServerConnection) {
        return (
            <Suspense fallback={<Loading/>}>
                <ConnectionFailed error={error}/>
            </Suspense>
        )
    }

    if (updating) {
        return (
            <Suspense fallback={<Loading/>}>
                <ApplicationIsUpdating/>
            </Suspense>
        )
    }


    if (authenticated) {
        return (

            //TODO Create here an implementation of the server side to fetch the userUUID.
            // eslint-disable-next-line react/jsx-pascal-case
            <UserLoggedIn_Render/>

        )
    } else {
        if (localStorage.jwtToken) {

            fetchUser({user_code: localStorage.user_code});

            return <Loading/>
        } else {
            // eslint-disable-next-line react/jsx-pascal-case
            return <UserLoggedOut_Render/>

        }
    }
}


/**
 * Navigation when you are logged in.
 * @property userUuid
 */
function UserLoggedIn_Render() {

    return (
        <div className="container-fluid p-0">

            <BrowserRouter>
                <Suspense fallback={<Loading/>}>
                    <Switch>

                        <Route path="/groups/group/:name/:group_code"
                               component={UserInGroup}/>
                        <Route component={UserNotInGroup}/>
                    </Switch>
                </Suspense>
            </BrowserRouter>

        </div>

    );
}

export default App;
