const liveServer = true;

let HOST = "https://www.ffcontroleren.nl";
let WEBSITE_URL = "https://www.tallytap.nl";
let PORT = "";
let SERVER_URL = HOST;

if (!liveServer) {
  HOST = "http://localhost";
  WEBSITE_URL = HOST + ":3000";
  PORT = "5000";
  SERVER_URL = HOST + ":" + PORT;
}

module.exports = {
  apiURL: {
    HOST: HOST,
    PORT: PORT,
    SERVER_URL: SERVER_URL,
    IS_THIS_LIVE_SERVER: liveServer,
  },
  websiteURL: {
    WEBSITE_URL: WEBSITE_URL,
  },
  version: {
    AT_LEAST_SERVER_VERSION: 0.56,
    CURRENT_CLIENT_VERSION: 0.42,
    DESCRIPTION: "Added support for multiple shops and added notification.",
  },
};

module.exports.versionHistory = {
  versionHistory: [
    {
      version: 0.41,
      description: "Updated bottom nav + prepaired extension shop",
    },
    {
      version: 0.4,
      description: "Groceries page now works with AH bug",
    },
    {
      version: 0.39,
      description:
        "Updated looks for AH. Added planting tree, removed service worker",
    },
    {
      version: 0.38,
      description:
        "Added contracts and link to user account settings in hamburger menu. ",
    },
    {
      version: 0.37,
      description: "Added update button in settings. ",
    },
    {
      version: 0.36,
      version_addition: "b",
      description: "Fixed last bugs for a new group.",
    },
    {
      version: 0.35,
      version_addition: "b",
      description: "Fixed zindex bug",
    },
    {
      version: 0.35,
      description: "Added gray background",
    },
    {
      version: 0.34,
      description: "Added wheel of fortune!",
    },
    {
      version: 0.33,
      version_addition: "b",
      description: "Removed CSS fixed tally counter location",
    },
    {
      version: 0.33,
      description: "Added service worker + filter on user. ",
    },
    {
      version: 0.32,
      version_addition: "a",
      description: "Updated bug in history list",
    },
    {
      version: 0.32,
      description: "Statistic filter options",
    },
    {
      version: 0.31,
      decription: "Products are editable",
    },
    {
      version: 0.3,
      version_addition: "a",
      description: "Updated landing page. Removed beta option",
    },
    {
      version: 0.3,
      description: "New landing page",
    },
    {
      version: 0.29,
      version_addition: "b",
      description: "Faster tally screen ",
    },
  ],
};
