import axois from "axios/index";
import ENV from "./environment";
import setAuthorizationToken from "./Autorization";

/**
 * @abstract
 */
export default class Database {

    /**
     *
     * @param route
     * @param config
     * @return {Promise<AxiosResponse<T>>}
     */
    static async get(route, config) {

        // console.info("getting from route: " + ENV.apiURL.SERVER_URL + route);

        if (!route) {
            return Promise.reject("Route cant be undefined or empty!")
        }

        return await axois.get(ENV.apiURL.SERVER_URL + route).then((res) => {
            // console.info(res);

            return Promise.resolve(res)
        }).catch((err) => {

            Database.checkErrorForRenewToken(err);

            console.error(err);
            return Promise.reject(err);
        })


    }

    static checkErrorForRenewToken(error) {

        if (error && error.response && error.response.data && error.response.data.renewToken) {

            const newToken = error.response.data.token;
            console.info("Gotten new token: " + newToken);

            if (newToken) {
                setAuthorizationToken(newToken);
                localStorage.setItem("jwtToken", newToken);

                window.location.reload();

            } else {
                Database.tokenExpired();
            }
        }

        // console.info("--- ERROR RESPONSE OBJECT --- ");
        if (error.response) {
            console.info(error.response.data.message);

            if (error.response.data.message === "Login please, RefreshToken not valid anymore") {
                Database.tokenExpired();
            }
        } else {
            console.info(error);
        }
    }


    static tokenExpired(){

        setAuthorizationToken();
        localStorage.removeItem("user_code");
        localStorage.removeItem("email");
        localStorage.removeItem("jwtToken");

        const sessionExpired = "Sessie is verlopen. Log opnieuw in. ";
        console.error(sessionExpired);
        alert(sessionExpired);
        window.location.reload();
    }

    static async put(route , data, config){
        if(!route){
            return Promise.reject("Route cant be undefined or empty. ");
        }

        route = ENV.apiURL.SERVER_URL + route;

        if(!data){
            data = {};
        }

        if(!config){
            config = {};
        }

        // console.info("Put request from route: " + route);
        // console.info("With data: ");
        // console.info(data);
        // console.info("With config: " + config);
        // console.info(config)

        return await axois.put(route, data, config)
            .then(res => {
                return Promise.resolve(res);
            })
            .catch(error => {
                console.error(error);
                console.error(error.response);

                Database.checkErrorForRenewToken(error);

                return Promise.reject(error);
            })

    }


    /**
     *
     * @param route
     * @param params
     * @param config
     * @return {Promise<AxiosResponse<T>>}
     */
    static async post(route , params, config, ) {


        // console.info("Posting from route: " + ENV.apiURL.SERVER_URL + route);

        // console.info("Posing with params: ");
        // console.info(params);

        if (!route) {
            return Promise.reject("Route cant be undefined or empty!")
        }

        if (!params) {
            params = {};
        }

        return await axois.post(ENV.apiURL.SERVER_URL + route, params, config)
            .then((res) => {
                // console.info(res);
                return Promise.resolve(res);
            })
            .catch((error) => {

                console.error(error);
                console.error(error.response);

                Database.checkErrorForRenewToken(error);

                if(error && error.response){
                    console.info(error.response.headers)
                }

                return Promise.reject(error)
            })

    }


}