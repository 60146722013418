// https://www.youtube.com/watch?v=cSUt8b2qapM

import User from "../../../user/User";
import Group from "../../../group/Group";
import {action, thunk} from "easy-peasy";

export default {
    // data:
    authenticated: false,
    currentUser: undefined,
    group: undefined,
    statistics: undefined,

    // Thunks:
    logoutUser: thunk(async (actions, payload) => {

        localStorage.clear();
        actions.logoutCurrentUser();
        actions.resetState();


    }),
    loginUser: thunk(async (actions, payload) => {
        return User.loginUser(payload.email, payload.password, payload.invite_code)
            .then((user_code) => {
                actions.fetchUser({user_code: user_code});
                return Promise.resolve("User logged in succesfully. ");
            })
    }),

    fetchUser: thunk(async (actions, payload, helpers) => {

        const userObject = await User.createUser(payload.user_code);

        actions.initCurrentUser({currentUser: userObject});

    }),
    fetchGroup: thunk(async (actions, payload) => {
        const groupObject = await Group.CreateGroup(payload.group_code);
        actions.initGroup({groupObject: groupObject});
    }),

    // User
    logoutCurrentUser: action((state, payload) => {

        if (state.currentUser) {
            state.currentUser.logOut();
        } else {
            console.error("There is no 'currentUser'. ")
        }

    }),
    initCurrentUser: action((state, payload) => {
        state.currentUser = payload.currentUser;
        state.authenticated = true;
    }),

    // Group
    initGroup: action((state, payload) => {
        state.group = payload.groupObject;
    }),
    // initSubGroup: action((state, payload) => {
    //     state.group = payload.groupObject;
    // }),
    removeCurrentGroup: action((state, payload) => {
        state.group = undefined;
    }),

    resetState: action((state, payload) => {

        state.group = undefined;
        state.currentUser = undefined;
        state.authenticated = false;
    }),

    setStatistics: action((state, payload) => {
        state.statistics = undefined;
        state.statistics = payload.statistics
    })
}



