import moment from "moment";

export class Mutation {

    createdAt;
    firstName;
    groupUUID;
    names;
    source;
    type;
    userUUID;
    userImageUrl;
    userImageAltText;
    sourceId;
    quantityPurchased;
    productGroupName;
    productGroupUUID;

    constructor(
        createdAt,
        // balance,
        firstNames,
        groupUUID,
        names,
        source,
        type,
        userUUIDs,
        description,
        userImageUrls,
        userImageAltTexts,
        sourceId,
        quantityPurchased,
        productGroupName,
        productGroupUUID

    ) {

        this.createdAt = moment(createdAt).format('MMMM DD YYYY HH:mm:ss');
        this.firstName = firstNames;
        this.groupUUID = groupUUID;
        this.names = names;
        this.source = source;
        this.type = type;
        this.userUUID = userUUIDs;
        this.description = description;
        this.userImageUrl = userImageUrls;
        this.userImageAltText = userImageAltTexts;
        this.sourceId = sourceId;
        this.quantityPurchased = Number(quantityPurchased);
        this.productGroupName = productGroupName;
        this.productGroupUUID = productGroupUUID;
    }

    getProductGroupName(){
        return this.productGroupName;
    }

    getProductGroupUUID(){
        return this.productGroupUUID;
    }

    getQuantityPurchased() {
        return Number(this.quantityPurchased);
    }

    getSource() {
        return this.source;
    }

    getSourceId() {
        return this.sourceId;
    }

    getDate() {
        return this.createdAt;
    }

    getName(){
        return this.firstName;
    }

    getUserCode() {
        return this.userUUID;
    }

    getType() {
        return this.type
    }

    getPrice(userUUID) {

        const index = this.userUUID.indexOf(userUUID);

        if (index === -1) {
            return 0;
        }


        if (this.balance[index]) {
            return this.balance[index].toFixed(2);
        }

        return 0;
    }

    getDescription() {

        if (!this.description) {
            return this.source;
        }

        return this.description;

    }
}

export default class TransactionClass {
    sourceId;
    mutations = [];
    description;
    date;
    type;
    productGroupUUID;
    productGroupName;

    constructor(transaction) {

        this.sourceId = transaction.sourceId;

        const mutations = transaction.transactionSet;

        for (const index in mutations) {

            const mutation = mutations[index];

            // console.log(transaction)

            this.mutations.push(
                new Mutation(
                    mutation.createdAt,
                    // mutation.balance,
                    mutation.firstName,
                    mutation.groupUUID,
                    mutation.name,
                    mutation.source,
                    mutation.type,
                    mutation.userUUID,
                    mutation.description,
                    mutation.userImageUrl,
                    mutation.userImageAltText,
                    mutation.sourceId,
                    mutation.quantity,
                    mutation.productGroupName,
                    mutation.productGroupUUID,
                ))
        }

        if (this.mutations.length > 0) {
            this.description = this.mutations[0].getDescription();
            this.type = this.mutations[0].getType();
            this.date = this.mutations[0].getDate();
            this.productGroupUUID = this.mutations[0].getProductGroupUUID();
            this.productGroupName = this.mutations[0].getProductGroupName();
        }
    }


    getProductGroupName(){
        return this.productGroupName;
    }

    getProductGroupUUID(){
        return this.productGroupUUID;
    }

    getMutations() {
        return this.mutations;
    }

    getSourceId() {
        return this.sourceId;
    }

    getNames() {
        const names = [];

        this.mutations.forEach(mutation => {
            names.push(mutation.getName());
        });

        return names;
    }

    getTotalTransactionQuantity() {
        let quantity = 0;

        this.mutations.forEach(mutation => {

            quantity += mutation.getQuantityPurchased();
        });

        return quantity;
    }

    getDescription() {
        return this.description;
    }

    getDate() {
        return this.date;
    }

    getType() {
        return this.type;
    }

    getQuantityUser(userUUID) {
        const mutation = this.mutations.filter((mutation) => {
            return mutation.getUserCode() === userUUID
        });

        if (mutation && mutation.length === 1) {
            return mutation[0].getQuantityPurchased();
        }

        return 0;
    }
}